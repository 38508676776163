import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, formatDate, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import { LANGUAGES, scannerOptions } from "../../constants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Helmet } from "react-helmet";
import Pagination from "react-bootstrap/Pagination";
import PaginationComponent from "./Paginator";
import ForumComments from "./ForumComments";
import TopContributers from "./TopContributers";
import Parser from "html-react-parser";
import PenguinChatMessage from "./PenguinChatMessage";
import PenguinChatListItem from "./PenguinChatListItem";

const PenguinChat = ({ lang, mode, user, setForceShowLogin }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [metaTitle, setMetaTitle] = useState(null);
  const [metaDesc, setMetaDesc] = useState(null);
  const [loggedin, setLoggedin] = useState(false);
  const [userComment, setUserComment] = useState("");
  const [questionDetails, setQuestionDetails] = useState(null);
  const [me, setMe] = useState(false);

  const [favouriteChats, setFavouriteChats] = useState([]);
  const [otherChats, setOtherChats] = useState([]);
  const [latestChat, setLatestChat] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [userQuestion, setUserQuestion] = useState("");
  const [canEdit, setCanEdit] = useState(true);
  const inputRef = useRef(null);
  const messageListRef = useRef(null);

  const navigate = useNavigate();
  const { chat_id } = useParams();

  const loadChatList = () => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.chatList}/`;
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      WebService.get(fullUrl, { headers }).then((response) => {
        let _data = response?.data;
        if (_data) {
          setFavouriteChats(_data.favorite_chats);
          setOtherChats(_data.other_chats);
          if (_data.latest_chat !== null && !chat_id) {
            setLatestChat(_data.latest_chat);
          } else {
            setLatestChat("new");
          }
        }
      });
    }
  };

  const reLoadChatList = (isDelete = false) => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.chatList}/`;
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      WebService.get(fullUrl, { headers }).then((response) => {
        let _data = response?.data;
        if (_data) {
          setFavouriteChats(_data.favorite_chats);
          setOtherChats(_data.other_chats);
          if (isDelete === true) {
            if (_data.latest_chat !== null) {
              setLatestChat(_data.latest_chat);
            } else {
              setLatestChat("new");
            }
          }
        }
      });
    }
  };

  const loadChatMessages = (chatId) => {
    if (dataExists(user)) {
      if (chatId === "new") {
        setChatMessages([]);
        return;
      }
      let fullUrl = `${apiEndPoints.chatbot}/${chatId}/`;
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };
      WebService.get(fullUrl, { headers }).then((response) => {
        let _data = response?.data;
        if (_data) {
          setChatMessages(_data?.messages);
          // console.log(_data);
          setCanEdit(_data?.can_edit);
        }
      });
    }
  };

  const handlePostQuestion = async (curMessage, allMessages) => {
    setChatMessages([...allMessages, curMessage]);
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.chatbot}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        query: userQuestion,
        chat_id: latestChat === "new" ? null : latestChat,
      };
      console.log(payload);
      // try {
      let response = await WebService.post(fullUrl, payload, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        let _data = response?.data;
        console.log(_data);
        if (latestChat === "new") {
          reLoadChatList();
        }

        setChatMessages([...allMessages, curMessage, _data?.data]);

        setLatestChat(_data?.data?.chat);
        // setPageNumber(1);
        // setUserComment("");
      } else {
        // setError(localString[lang]["errorCreatingQuestion"]);
      }
    }
  };

  useEffect(() => {
    if (dataExists(user)) {
      loadChatList();
      setLoggedin(true);
    } else {
      setForceShowLogin(true);
    }
    if (chat_id) {
      setLatestChat(chat_id);
    }
  }, []);

  useEffect(() => {
    inputRef.current.style.height = "auto";
    messageListRef.current.scrollTo(0, messageListRef.current.scrollHeight);
    // console.log(messageListRef.current);
  }, [chatMessages]);

  useEffect(() => {
    if (latestChat !== null) {
      loadChatMessages(latestChat);
    }
  }, [latestChat]);

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
      </Helmet>
      <Stack
        className="chat-list-dropdown ps-2 pe-2"
        // className={`content ${
        //   mode === "dark" ? "background-dark" : "background-light"
        // }`}
        // gap={3}
      >
        <InputGroup className="pt-2">
          <div className="d-flex w-100">
            <Form.Select
              aria-label="Category select"
              size="sm"
              className="me-1"
              // value={category}
              onChange={(e) => {
                // let value = e.target.value;
                // if (value === 0) {
                //   setCategory(0);
                // } else {
                //   window.location = `/forum/filter?category=${value}`;
                //   // setCategory(value);
                //   // setSubcategory(0);
                // }
              }}
            >
              {favouriteChats?.map((chat) => {
                return (
                  <option key={chat.id} value={chat.id}>
                    {chat.title}
                  </option>
                );
              })}
              {otherChats?.map((chat) => {
                return (
                  <option key={chat.id} value={chat.id}>
                    {chat.title}
                  </option>
                );
              })}
            </Form.Select>
          </div>
        </InputGroup>
      </Stack>
      <div className="d-flex">
        <Stack
          className={`content full-height chat-left overflow-scroll ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
          gap={3}
        >
          <div className="p-3">
            <Button
              onClick={() => {
                setLatestChat("new");
                let newChat = {
                  id: "new",
                  name: ".......",
                  favorite: false,
                  chat_count: 0,
                };
                setOtherChats([newChat, ...otherChats]);
                // setChatMessages([...allMessages, curMessage]);
                // navigate("/chat/new");
              }}
              variant="primary"
              size="sm"
              className="w-50 float-end"
            >
              <i className="bi bi-plus"></i> New Chat
            </Button>
          </div>
          <div className="p-3">
            <h6 className="ps-2 pt-3 text-secondary-emphasis text-decoration-underline">
              Favourites
            </h6>
            {favouriteChats?.length > 0 &&
              favouriteChats?.map((chat) => (
                <PenguinChatListItem
                  key={chat.id}
                  chat={chat}
                  setLatestChat={setLatestChat}
                  reLoadChatList={reLoadChatList}
                  user={user}
                  mode={mode}
                  lang={lang}
                  latestChat={latestChat}
                />
              ))}
            <hr />
            <h6 className="ps-2 pt-3 text-secondary-emphasis text-decoration-underline">
              Others
            </h6>

            {otherChats?.length > 0 &&
              otherChats?.map((chat) => (
                <PenguinChatListItem
                  key={chat.id}
                  chat={chat}
                  setLatestChat={setLatestChat}
                  reLoadChatList={reLoadChatList}
                  user={user}
                  mode={mode}
                  lang={lang}
                  latestChat={latestChat}
                />
              ))}
          </div>
        </Stack>

        <Stack
          className={`content full-height chat-right ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
        >
          <div
            ref={messageListRef}
            className="message-container w-100 overflow-scroll p-3"
          >
            {chatMessages?.length > 0 &&
              chatMessages.map((message, index) => (
                <PenguinChatMessage
                  key={index}
                  message={message}
                  user={user}
                  lang={lang}
                  mode={mode}
                />
              ))}
          </div>
          <div className="p-3">
            <InputGroup className="bottom-0">
              <Form.Control
                as="textarea"
                disabled={!canEdit}
                ref={inputRef}
                rows={1}
                value={userQuestion}
                onChange={(e) => {
                  console.log(e);

                  setUserQuestion(e.target.value);
                }}
                placeholder="Type your message here..."
                style={{
                  resize: "none",
                  overflow: "hidden",
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    if (userQuestion === "" || !canEdit) return;
                    let curMessage = {
                      id: Math.random(),
                      chat: latestChat,
                      user: user?.id,
                      body: { message: userQuestion },
                      is_bot: false,
                    };
                    handlePostQuestion(curMessage, chatMessages);
                    setUserQuestion("");
                  }
                }}
                onInput={(e) => {
                  e.target.style.height = "auto"; // Reset height
                  e.target.style.height = `${e.target.scrollHeight}px`; // Adjust height to content
                }}
              />
              <div className="">
                <Button
                  onClick={() => {
                    if (userQuestion === "" || !canEdit) return;
                    let curMessage = {
                      id: Math.random(),
                      chat: latestChat,
                      user: user?.id,
                      body: { message: userQuestion },
                      is_bot: false,
                    };
                    handlePostQuestion(curMessage, chatMessages);
                    setUserQuestion("");
                  }}
                  variant="primary"
                  size="sm"
                  className="send-button"
                >
                  <i className="bi bi-send"></i>
                  {/* Sending */}
                </Button>
              </div>
            </InputGroup>
          </div>
        </Stack>
      </div>
    </>
  );
};

export default PenguinChat;
