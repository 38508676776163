import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import { THEMES } from "../constants";
import Screener from "../components/home/Screener";
import ScrollToTop from "../components/home/ScrollToTop";
import Share from "../components/home/Share";
import SPSidebar from "../components/header/Sidebar";
import Portfolio from "../components/home/Portfolio";
import UserPortfolio from "../components/home/UserPortfolio";
import { useParams } from "react-router-dom";

const UserPortfolios = ({ user }) => {
  const [mode, setMode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [starredTickers, setStarredTickers] = useState([]);
  const [portfolioId, setPortfolioId] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(
    JSON.parse(localStorage.getItem("isDarkMode"))
  );
  const [lang, setLang] = useState(
    JSON.parse(localStorage.getItem("language")) || "en"
  );

  const { id } = useParams();
  useEffect(() => {
    let lsIsDarkMode = JSON.parse(localStorage.getItem("isDarkMode"));
    let isDarkMode = lsIsDarkMode === null ? true : lsIsDarkMode;
    setIsDarkMode(isDarkMode);
    setStarredTickers(JSON.parse(localStorage.getItem("starredTickers")));
    setPortfolioId(id);
    // eslint-disable-next-line
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    localStorage.setItem("isDarkMode", isDarkMode);
    const htmlElement = document.querySelector("html");
    htmlElement.setAttribute(
      "data-bs-theme",
      isDarkMode ? THEMES.DARK : THEMES.LIGHT
    );
    setMode(isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  useEffect(() => {
    mode && setLoading(false);
  }, [mode]);

  return (
    <>
      {loading ? (
        <> </>
      ) : (
        <>
          <Header
            toggleDarkMode={toggleDarkMode}
            lang={lang}
            setLang={setLang}
            mode={mode}
            starredTickers={starredTickers}
            user={user}
          />
          <SPSidebar mode={mode} lang={lang} user={user} />
          <section className="section" id="stock-penguins-section">
            {portfolioId && (
              <UserPortfolio
                lang={lang}
                mode={mode}
                user={user}
                portfolioId={portfolioId}
              />
            )}
          </section>
          <Share />
          <ScrollToTop />
        </>
      )}
    </>
  );
};

export default UserPortfolios;
