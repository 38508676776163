import React, { StrictMode, useEffect, useMemo, useState, useRef } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./home.scss";
import { dataExists, formatDate, nFormatter } from "../../helpers";
import { localString } from "../../localization/localString";
import moment from "moment";
import axios from "axios";
import { WebService } from "../../services/webServices";
import { apiEndPoints } from "../../services/endpoints";
import { FORUM_CATEGORIES, LANGUAGES, scannerOptions } from "../../constants";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Helmet } from "react-helmet";
import Pagination from "react-bootstrap/Pagination";
import useDebounce from "./Debounce";
import { Typeahead } from "react-bootstrap-typeahead";
import compaines from "../../data/companies.json";
import PaginatedList from "./PaginatedList";
import TopContributers from "./TopContributers";
import ImageUploadModal from "./ImageUploadModal";

const ForumProfile = ({ lang, mode, user, setUser, setForceShowLogin }) => {
  const { username } = useParams();
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [newUsername, setNewUsername] = useState(username);
  const [validUsername, setValidUsername] = useState(username);
  const [followerCount, setFollowerCount] = useState(0);
  const [following, setFollowing] = useState(false);
  const [upvoteCount, setUpvoteCount] = useState(0);
  const [createdAt, setCreatedAt] = useState(null);
  const [xURL, setxURL] = useState("");
  const [linkedinURL, setLinkedinURL] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [me, setMe] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [userTitle, setUserTitle] = useState("");
  const [bio, setBio] = useState("");
  const [loggedin, setLoggedIn] = useState(false);
  const [error, setError] = useState(null);
  const [invalidUser, setInvalidUser] = useState(false);
  const [metaTitle, setMetaTitle] = useState(null);
  const [metaDesc, setMetaDesc] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(null);
  const debouncedUsername = useDebounce(newUsername, 500);

  const allowSubmit = () => {
    return (
      loggedin &&
      userFirstName !== null &&
      userFirstName !== "" &&
      userFirstName !== undefined &&
      userLastName !== null &&
      userLastName !== "" &&
      userLastName !== undefined &&
      validUsername &&
      isValidUrl(linkedinURL) &&
      isValidUrl(xURL) &&
      isValidUrl(websiteURL)
    );
  };

  const getUser = async () => {
    let fullUrl = `${apiEndPoints.getUser}/`;

    let headers = {
      "Content-Type": "application/json",
      //   Authorization: `Bearer ${user?.token}`,
    };
    if (dataExists(user)) {
      headers["Authorization"] = `Bearer ${user?.token}`;
    }

    let payload = {
      username: newUsername,
    };
    // try {
    let response = await WebService.post(fullUrl, payload, {
      headers: headers,
    });
    if (response?.status >= 200 && response?.status < 300) {
      let _data = response?.data;
      setUserTitle(_data.title);
      setProfilePicture(_data.profile_picture);
      setUserFirstName(_data.first_name);
      setUserLastName(_data.last_name);
      setxURL(_data.x_url);
      setLinkedinURL(_data.linkedin_url);
      setWebsiteURL(_data.website_url);
      setNewUsername(_data.username);
      setBio(_data.bio);
      setFollowerCount(_data.followers);
      setUpvoteCount(_data.total_upvotes);
      setCreatedAt(_data.created_at);
      setFollowing(_data.following);
      setUserId(_data.id);
      setInvalidUser(false);
    } else {
      setInvalidUser(true);
      setError(localString[lang]["errorCreatingQuestion"]);
    }
  };

  const isValidUrl = (string) => {
    if (string === "" || string === null || string === undefined) return true;
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  useEffect(() => {
    getUser();
    if (dataExists(user)) {
      setLoggedIn(dataExists(user));
      if (user.username === username) {
        setMe(true);
      }
    }
  }, []);

  useEffect(() => {
    async function fetchUsername() {
      if (debouncedUsername) {
        if (editMode === false) return;
        console.log("Fetch API call with:", debouncedUsername);
        if (newUsername === null || newUsername === "") {
          setValidUsername(false);
        } else {
          let fullUrl = `${apiEndPoints.username}/`;

          let headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          };

          let payload = {
            username: newUsername,
          };
          // try {
          let response = await WebService.post(fullUrl, payload, {
            headers: headers,
          });
          if (response?.status >= 200 && response?.status < 300) {
            setValidUsername(true);
          } else {
            setValidUsername(false);
          }
        }
      }
    }
    fetchUsername();
  }, [debouncedUsername]);

  const follow = async () => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.follow}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        user: userId,
      };
      // try {
      let response = await WebService.post(fullUrl, payload, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        setFollowing(true);
        // } else {
        //   setValidUsername(false);
      }
    }
  };
  const unfollow = async () => {
    if (dataExists(user)) {
      let fullUrl = `${apiEndPoints.unFollow}/`;

      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
      };

      let payload = {
        user: userId,
      };
      // try {
      let response = await WebService.post(fullUrl, payload, {
        headers: headers,
      });
      if (response?.status >= 200 && response?.status < 300) {
        setFollowing(false);
        // } else {
        //   setValidUsername(false);
      }
    }
  };

  const handleEditClick = () => {
    if (me === true) {
      setEditMode(true);
    }
  };
  const handleSaveClick = async () => {
    if (me === true) {
      if (allowSubmit()) {
        let fullUrl = `${apiEndPoints.updateUser}/`;

        let headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        };

        let payload = {
          first_name: userFirstName,
          last_name: userLastName,
          username: newUsername,
          bio: bio,
          title: userTitle,
          x_url: xURL,
          linkedin_url: linkedinURL,
          website_url: websiteURL,
        };
        // try {
        let response = await WebService.post(fullUrl, payload, {
          headers: headers,
        });
        if (response?.status >= 200 && response?.status < 300) {
          let _userJson = response?.data;
          console.log(_userJson);
          setEditMode(false);
          _userJson["token"] = user.token;
          _userJson["refresh"] = user.refresh;
          localStorage.setItem("user", JSON.stringify(_userJson));
          setUser(_userJson);
        } else {
          setError(localString[lang]["errorCreatingQuestion"]);
        }

        // setEditMode(false);
      } else {
        console.log("Cannot Save");
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
      </Helmet>
      <div className="d-flex min-vh-100">
        <Stack
          className={`content screener forum-left ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
        >
          {invalidUser ? (
            <div className="m-auto">
              <div className="m-auto text-danger-emphasis">
                <p  className="text-center">
                <i class="bi bi-person-x display-1"></i>
                </p>
                <p>{localString[lang]["invalidUsername"]}</p>
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between flex-row flex-wrap bd-highlight forum-container">
                <div className="d-flex justify-content-between align-items-center mt-2 mb-3 w-100">
                  <div className="d-flex me-3">
                    <div>
                      {profilePicture ? (
                        <img
                          className="user-profile rounded-circle"
                          src={profilePicture}
                        ></img>
                      ) : (
                        <img
                          className="user-profile rounded-circle"
                          src="/assets/user.png"
                        ></img>
                      )}
                      {editMode && (
                        <div
                          className="cursor-pointer text-secondary-emphasis position-relative image-upload-button"
                          onClick={() => {
                            setShowUploadModal(true);
                          }}
                        >
                          {/* <i className="bi bi-file-earmark-arrow-up-fill"></i> */}
                          <i className="bi bi-arrow-up-circle-fill"></i>
                        </div>
                      )}
                    </div>
                    <div>
                      {editMode === false ? (
                        <div className="screener-title">{`${userFirstName} ${userLastName}`}</div>
                      ) : (
                        <InputGroup className="mb-3">
                          {/* <Form.Label>{localString[lang]["title"]}</Form.Label> */}
                          <Form.Control
                            type="text"
                            size="sm"
                            placeholder="First Name"
                            className={
                              userFirstName === "" ||
                              userFirstName === null ||
                              userFirstName === undefined
                                ? "border-danger-subtle"
                                : ""
                            }
                            value={userFirstName}
                            onChange={(e) => {
                              setUserFirstName(e.target.value);
                            }}
                          />
                          <Form.Control
                            type="text"
                            size="sm"
                            // className="ms-2"
                            className={
                              userLastName === "" ||
                              userLastName === null ||
                              userLastName === undefined
                                ? "border-danger-subtle ms-2"
                                : "ms-2"
                            }
                            placeholder="Last Name"
                            value={userLastName}
                            onChange={(e) => {
                              setUserLastName(e.target.value);
                            }}
                          />
                        </InputGroup>
                      )}
                      {editMode && (
                        <div className="forum-list-title">
                          <InputGroup className="mb-3">
                            <Form.Control
                              type="text"
                              size="sm"
                              placeholder="Username"
                              className={
                                validUsername ? "" : "border-danger-subtle"
                              }
                              value={newUsername}
                              onChange={(e) => {
                                let value = e.target.value;

                                let result = value.replace(/ +/g, "-");
                                result = result.replace(/\./g, "");
                                result = result.toLowerCase();
                                setNewUsername(result);
                                if (result === null || result === "") {
                                  setValidUsername(false);
                                }
                              }}
                            />
                          </InputGroup>
                        </div>
                      )}
                      <div className="forum-list-title">
                        {editMode === false ? (
                          <div>{userTitle ? userTitle : "-"}</div>
                        ) : (
                          <InputGroup className="mb-3">
                            <Form.Control
                              type="text"
                              size="sm"
                              placeholder="Title"
                              value={userTitle}
                              onChange={(e) => {
                                setUserTitle(e.target.value);
                              }}
                            />
                          </InputGroup>
                        )}
                      </div>
                      <div className="forum-list-title">
                        <div>{`${
                          localString[lang]["memberSince"]
                        }: ${formatDate(createdAt)}`}</div>
                      </div>
                      <div className="forum-list-title">
                        <strong>
                          <span>{`${localString[lang]["followers"]}: ${followerCount}`}</span>
                          <span className="ms-2">{`${localString[lang]["noOfUpvotes"]}: ${upvoteCount}`}</span>
                        </strong>
                      </div>
                    </div>
                  </div>
                  {me ? (
                    editMode === false ? (
                      <div
                        className="p-2 cursor-pointer text-primary-emphasis align-self-baseline"
                        onClick={() => {
                          handleEditClick();
                        }}
                      >
                        <i className="bi bi-pencil-square"></i>
                      </div>
                    ) : (
                      <div
                        className="p-2 cursor-pointer text-primary-emphasis align-self-baseline"
                        onClick={() => {
                          handleSaveClick();
                        }}
                      >
                        <i className="bi bi-floppy"></i>
                      </div>
                    )
                  ) : (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        loggedin ? (
                          <span></span>
                        ) : (
                          <Tooltip id="tooltip">
                            {localString[lang].loginToSP}
                          </Tooltip>
                        )
                      }
                    >
                      <div className="p-2 cursor-pointer">
                        {!following ? (
                          <Button
                            variant="info"
                            size="sm"
                            // disabled={!loggedin}
                            onClick={() => {
                              if (!loggedin) {
                                setForceShowLogin(true);
                              } else {
                                follow();
                              }
                            }}
                          >
                            {localString[lang]["follow"]}
                          </Button>
                        ) : (
                          <Button
                            variant="info"
                            size="sm"
                            // disabled={!loggedin}
                            onClick={() => {
                              if (!loggedin) {
                                setForceShowLogin(true);
                              } else {
                                unfollow();
                              }
                            }}
                          >
                            {localString[lang]["unfollow"]}
                          </Button>
                        )}
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
              <div className="bd-highlight forum-container mt-0">
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="textarea"
                      disabled={!editMode}
                      // className={!description ? "border-danger-subtle" : ""}
                      rows={2}
                      placeholder="Tell something about you"
                      value={bio}
                      onChange={(e) => {
                        setBio(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>
                <div className="d-flex justify-content-between mb-4">
                  <div className={`${editMode ? "" : "d-flex"} w-100`}>
                    {editMode ? (
                      <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text size="sm">
                          {localString[lang]["xURL"]}
                        </InputGroup.Text>
                        <Form.Control
                          size="sm"
                          placeholder={localString[lang]["xURLPlaceHolder"]}
                          aria-label={localString[lang]["xURL"]}
                          className={
                            !isValidUrl(xURL) ? "border-danger-subtle" : ""
                          }
                          value={xURL}
                          onChange={(e) => {
                            setxURL(e.target.value);
                          }}
                        />
                      </InputGroup>
                    ) : (
                      <div className="screener-title">
                        {xURL && (
                          <a href={xURL} target="_blank" rel="noreferrer">
                            <i className="bi bi-twitter-x h4"></i>
                          </a>
                        )}
                      </div>
                    )}
                    {editMode ? (
                      <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text size="sm">
                          {localString[lang]["linkedinURL"]}
                        </InputGroup.Text>
                        <Form.Control
                          placeholder={
                            localString[lang]["linkedinURLPlaceHolder"]
                          }
                          aria-label={localString[lang]["linkedinURL"]}
                          className={
                            !isValidUrl(linkedinURL)
                              ? "border-danger-subtle"
                              : ""
                          }
                          size="sm"
                          value={linkedinURL}
                          onChange={(e) => {
                            setLinkedinURL(e.target.value);
                          }}
                        />
                      </InputGroup>
                    ) : (
                      <div className="screener-title">
                        {linkedinURL && (
                          <a href={linkedinURL} target="_blank" rel="noreferrer">
                            <i className="bi bi-linkedin h4"></i>
                          </a>
                        )}
                      </div>
                    )}
                    {editMode ? (
                      <InputGroup size="sm" className="mb-3">
                        <InputGroup.Text size="sm">
                          {localString[lang]["websiteURL"]}
                        </InputGroup.Text>
                        <Form.Control
                          placeholder={
                            localString[lang]["websiteURLPlaceHolder"]
                          }
                          aria-label={localString[lang]["websiteURL"]}
                          className={
                            !isValidUrl(websiteURL)
                              ? "border-danger-subtle"
                              : ""
                          }
                          size="sm"
                          value={websiteURL}
                          onChange={(e) => {
                            setWebsiteURL(e.target.value);
                          }}
                        />
                      </InputGroup>
                    ) : (
                      <div className="screener-title">
                        {websiteURL && (
                          <a href={websiteURL} target="_blank" rel="noreferrer">
                            <i className="bi bi-browser-chrome h4"></i>
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <PaginatedList
                lang={lang}
                mode={mode}
                user={user}
                category={""}
                username={newUsername}
                title={localString[lang]["postsAndAnalysis"]}
                hideUsername={true}
              />
            </>
          )}
        </Stack>

        <Stack
          className={`content screener forum-right ${
            mode === "dark" ? "background-dark" : "background-light"
          }`}
          gap={3}
        >
          <TopContributers
            mode={mode}
            lang={lang}
            user={user}
            setForceShowLogin={setForceShowLogin}
          />
        </Stack>
        <ImageUploadModal
          visibility={showUploadModal}
          setVisibility={setShowUploadModal}
          mode={mode}
          lang={lang}
          user={user}
          setUser={setUser}
          setProfilePicture={setProfilePicture}
        />
      </div>
    </>
  );
};

export default ForumProfile;
